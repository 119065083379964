export default `
C.D.S.  -  SIMBAD4 rel 1.7  -  2020.12.12CET16:58:09

Vmag <= 6 & Distance.distance < 33 & Distance.unit = "pc"
---------------------------------------------------------

Number of objects : 384

 # ;            identifier             ;            identifier             ;      coord1 (FK4,J2000/2000)      ;  plx  ;Mag V 
---;-----------------------------------;-----------------------------------;-----------------------------------;-------;------
1  ;* tau Cyg A                        ;TYC 3169-3876-1                    ;318.6975700077671 +38.0454298055197;  49.58; 3.83 
2  ;* 61 Cyg A                         ;TYC 3168-2800-1                    ;316.7243855215882 +38.7493977485257; 285.95; 5.21 
3  ;* lam And                          ;TYC 3638-2515-1                    ;354.3906812368 +46.4581448062      ;  37.87; 3.82 
4  ;* ome And                          ;TYC 3265-2401-1                    ;021.9137912642839 +45.4066852257057;  34.94; 4.83 
5  ;HD 10307                           ;TYC 2823-2455-1                    ;025.4461378109868 +42.6134517606675;  76.52; 4.96 
6  ;* 7 And                            ;TYC 3631-2231-1                    ;348.1371783192 +49.4062045385      ;  40.67; 4.52 
7  ;* 12 Per                           ;TYC 2849-2054-1                    ;040.5618691681401 +40.1939339497264;  42.40; 4.92 
8  ;* alf Lac                          ;TYC 3628-3193-1                    ;337.8225740358 +50.2824875238      ;  31.79; 3.77 
9  ;HD 219134                          ;TYC 4006-1866-1                    ;348.3203848275524 +57.1683599533828; 153.08; 5.570
10 ;NAME Achird                        ;TYC 3663-2669-1                    ;012.2758972616 +57.8151988219      ; 167.98; 3.44 
11 ;* tet Per                          ;TYC 3304-2737-1                    ;041.0496711052 +49.2284523550      ;  89.87; 4.11 
12 ;HD 5015                            ;TYC 4017-2318-1                    ;013.2671689991145 +61.1239869334541;  53.19; 4.80 
13 ;NAME Ruchbah                       ;TYC 4031-3289-1                    ;021.4536621083 +60.2352984851      ;  32.81; 2.680
14 ;HD 10780                           ;TYC 4040-2023-1                    ;026.9365106959836 +63.8525202049456;  99.57; 5.63 
15 ;HD 17948                           ;TYC 4048-1977-1                    ;043.9868981802378 +61.5211543495580;  37.43; 5.584
16 ;* tet Cyg                          ;TYC 3564-3157-1                    ;294.1101890378 +50.2210888949      ;  54.54; 4.48 
17 ;* 16 Cyg A                         ;TYC 3565-1524-1                    ;295.4536040090256 +50.5250488850109;  47.28; 5.95 
18 ;* eta Cep                          ;TYC 4246-1967-1                    ;311.3220207227 +61.8387797097      ;  70.10; 3.410
19 ;HD 210884                          ;TYC 4467-2426-1                    ;332.6612368823401 +70.1325840674286;  30.65; 5.52 
20 ;NAME Capella                       ;TYC 3358-3141-1                    ;079.17208839 +45.99799510          ;  76.20; 0.08 
21 ;NAME Altais                        ;TYC 4444-2239-1                    ;288.1383648522 +67.6615309661      ;  33.48; 3.07 
22 ;NAME Alsafi                        ;TYC 4448-2481-1                    ;293.0895585787 +69.6611700646      ; 173.77; 4.680
23 ;* 59 Dra                           ;TYC 4583-2836-1                    ;287.2907246911416 +76.5604942289390;  36.41; 5.107
24 ;* 36 Dra                           ;TYC 4209-2018-1                    ;273.4739226645066 +64.3972694432304;  43.77; 4.987
25 ;* chi Dra                          ;TYC 4437-1491-1                    ;275.26368671 +72.73283367          ; 124.11; 3.580
26 ;NAME Dziban                        ;TYC 4436-1425-1                    ;265.4833318754638 +72.1488441441725;  46.68; 4.55 
27 ;* psi01 Dra B                      ;TYC 4436-1424-1                    ;265.4917049106540 +72.1568810062418;  43.99; 5.53 
28 ;HD 90089                           ;TYC 4632-2151-1                    ;157.7688128785698 +82.5586617919454;  43.99; 5.252
29 ;* mu. Dra A                        ;TYC 3890-1396-2                    ;256.3334908523034 +54.4700051872830;  36.80; 5.66 
30 ;* mu. Dra B                        ;TYC 3890-1396-1                    ;256.3338551587996 +54.4706140636624;  36.80; 5.69 
31 ;HD 151613                          ;TYC 3882-2597-1                    ;251.3241482273643 +56.7818689093955;  39.29; 4.837
32 ;NAME Athebyne                      ;TYC 4190-2568-1                    ;245.9975229532701 +61.5142134770371;  35.42; 2.74 
33 ;* tet Dra                          ;TYC 3883-1543-1                    ;240.4719295577 +58.5652298605      ;  47.54; 4.000
34 ;* d UMa                            ;TYC 4376-2052-1                    ;143.6200467212904 +69.8303282144070;  30.93; 4.57 
35 ;* pi.01 UMa                        ;TYC 4133-1971-1                    ;129.7985522627273 +65.0209021193706;  69.20; 5.64 
36 ;* sig02 UMa A                      ;TYC 4141-1496-1                    ;137.5979016097786 +67.1340124744610;  48.53; 4.813
37 ;* 22 Lyn                           ;TYC 3401-1647-1                    ;112.4829242189080 +49.6724633365572;  49.03; 5.357
38 ;NAME Edasich                       ;TYC 3875-1620-1                    ;231.2320574136 +58.9660425390      ;  32.23; 3.29 
39 ;* h UMa                            ;TYC 4139-1304-1                    ;142.8818926159 +63.0618513523      ;  41.99; 3.67 
40 ;* c UMa                            ;TYC 4135-1915-1                    ;138.5853455557000 +61.4233124913262;  50.87; 5.20 
41 ;* 30 Lyn                           ;TYC 3803-1703-1                    ;125.1083561216633 +57.7432772073575;  31.02; 5.877
42 ;NAME Taiyi                         ;TYC 4168-930-1                     ;193.8686746653538 +65.4384492512841;  34.03; 5.225
43 ;* chi Her                          ;TYC 3057-2021-1                    ;238.1685768685303 +42.4515019939801;  63.16; 4.62 
44 ;HD 111456                          ;TYC 4162-1200-1                    ;192.1640775004016 +60.3197109917934;  38.19; 5.831
45 ;* f UMa                            ;TYC 3430-1673-1                    ;137.2175044268 +51.6046444763      ;  34.70; 4.48 
46 ;* i Boo A                          ;TYC 3484-1580-1                    ;225.9464594505153 +47.6539622820317;  77.25; 5.136
47 ;* iot Boo                          ;TYC 3478-1333-1                    ;214.0410600949724 +51.3672141848625;  34.30; 4.75 
48 ;* tet Boo                          ;TYC 3478-1332-1                    ;216.2988353795 +51.8507260444      ;  68.82; 4.05 
49 ;NAME Mizar B                       ;TYC 3850-1386-1                    ;200.9843801134215 +54.9217884279195;  40.50; 3.88 
50 ;NAME Mizar A                       ;TYC 3850-1385-1                    ;200.981129504 +54.925343017        ;  39.4 ; 2.220
51 ;NAME Alcor                         ;TYC 3850-1384-1                    ;201.3061081097 +54.9879407599      ;  39.91; 4.010
52 ;* 78 UMa A                         ;TYC 3845-1189-1                    ;195.1817125951972 +56.3663309145633;  39.18; 5.02 
53 ;NAME Megrez                        ;TYC 3837-1070-1                    ;183.8562263982 +57.0325975241      ;  40.51; 3.320
54 ;NAME Merak                         ;TYC 3827-1079-1                    ;165.4600635188 +56.3824126663      ;  40.90; 2.37 
55 ;* 37 UMa                           ;TYC 3819-1372-1                    ;158.7901443299255 +57.0826265269935;  37.28; 5.148
56 ;* tet UMa                          ;TYC 3432-1451-1                    ;143.2140690063 +51.6772955308      ;  74.19; 3.18 
57 ;NAME Xuange                        ;TYC 3472-1264-1                    ;214.0955940772 +46.0882932752      ;  32.94; 4.18 
58 ;* 15 LMi                           ;TYC 3433-1531-1                    ;147.1471356120350 +46.0210073012398;  52.90; 5.10 
59 ;* 10 UMa                           ;TYC 2986-1978-1                    ;135.15968187 +41.78294787          ;  62.23; 3.960
60 ;NAME Seginus                       ;TYC 3036-1151-1                    ;218.0191415499 +38.3082460919      ;  37.58; 3.02 
61 ;NAME Tania Borealis                ;TYC 3007-1286-1                    ;154.2738056842286 +42.9143788810977;  31.78; 3.45 
62 ;* 19 LMi                           ;TYC 2996-1547-1                    ;149.4208118023896 +41.0556330060325;  34.71; 5.104
63 ;* tau03 Eri                        ;TYC 6438-1110-1                    ;045.5976905547 -23.6245588518      ;  36.80; 4.09 
64 ;* eps For                          ;TYC 6444-112-1                     ;045.4065759312483 -28.0915872753223;  31.25; 5.85 
65 ;* kap01 Cet                        ;TYC 59-1947-1                      ;049.8401569245 +03.3701488093      ; 109.41; 4.85 
66 ;* 94 Cet                           ;TYC 4708-1423-1                    ;048.1932441722552 -01.1961542842669;  44.37; 5.070
67 ;* alf For                          ;* alf For                          ;048.0186528493 -28.9877123960      ;  70.24; 3.85 
68 ;NAME Ran                           ;TYC 5296-1533-1                    ;053.2324594430 -09.4583216097      ; 310.94; 3.73 
69 ;* 10 Tau                           ;TYC 64-1569-1                      ;054.2170336142956 +00.3995437342889;  71.62; 4.30 
70 ;NAME Rana                          ;TYC 5303-1320-1                    ;055.8118626089 -09.7634520550      ; 110.61; 3.54 
71 ;* tau06 Eri                        ;TYC 6448-1370-1                    ;056.7118292444 -23.2497974491      ;  56.73; 4.20 
72 ;* psi Tau                          ;TYC 1826-1575-1                    ;061.7516563742909 +29.0012949950629;  36.22; 5.2  
73 ;HD 25457                           ;TYC 4725-1276-1                    ;060.6528762939186 -00.2689666606112;  53.27; 5.38 
74 ;* ome02 Tau                        ;TYC 1272-1126-1                    ;064.3150190405221 +20.5785768028751;  34.12; 4.914
75 ;NAME Keid                          ;TYC 5312-2325-1                    ;063.8177825736 -07.6529197538      ; 200.62; 4.43 
76 ;* 51 Eri                           ;TYC 4739-1551-1                    ;069.4003339172318 -02.4735877647230;  33.58; 5.209
77 ;* alf Cae                          ;TYC 7589-1693-1                    ;070.14033289 -41.86381833          ;  49.59; 4.45 
78 ;NAME Tabit                         ;TYC 96-1462-1                      ;072.4598210686 +06.9612475137      ; 123.94; 3.190
79 ;HD 30562                           ;TYC 4748-1630-1                    ;072.1513931063552 -05.6740804208756;  38.20; 5.77 
80 ;* 58 Eri                           ;TYC 5892-1953-1                    ;071.9010199041722 -16.9345028917529;  75.52; 5.50 
81 ;* bet Cae                          ;TYC 7045-130-1                     ;070.5143564703212 -37.1443936483040;  34.75; 5.05 
82 ;* 68 Eri                           ;TYC 4759-1670-1                    ;077.1818681372079 -04.4562363922693;  39.81; 5.119
83 ;* lam Aur                          ;TYC 2900-2157-1                    ;079.7850756898735 +40.0990531136352;  80.11; 4.71 
84 ;HD 34721                           ;TYC 5906-910-1                     ;079.7101166414435 -18.1300873449171;  40.47; 5.96 
85 ;* omi Col                          ;TYC 7051-2305-1                    ;079.3710562360 -34.8952511398      ;  30.82; 4.83 
86 ;HD 38858                           ;TYC 4776-1306-1                    ;087.1453805087982 -04.0946624583194;  65.55; 5.97 
87 ;* zet Lep                          ;TYC 5359-1778-1                    ;086.7387325119 -14.8219730275      ;  46.28; 3.525
88 ;* gam Lep                          ;TYC 5930-2197-1                    ;086.1156209944 -22.4484103059      ; 112.02; 3.60 
89 ;* chi01 Ori                        ;TYC 1320-2118-1                    ;088.5955368536 +20.2761660285      ; 115.43; 4.40 
90 ;* eta Lep                          ;TYC 5360-1370-1                    ;089.1010326910 -14.1677189253      ;  67.21; 3.72 
91 ;NAME Wazn                          ;TYC 7069-1482-1                    ;087.7398276006 -35.7683377288      ;  37.41; 3.12 
92 ;* nu.02 CMa                        ;TYC 5956-2621-1                    ;099.1708129751 -19.2558832335      ;  50.63; 3.91 
93 ;* 37 Gem                           ;TYC 1898-2479-1                    ;103.8275522625088 +25.3756973939182;  57.24; 5.748
94 ;NAME Alzirr                        ;TYC 754-2356-1                     ;101.3221338017 +12.8955920937      ;  55.56; 3.36 
95 ;* e Gem                            ;TYC 760-2586-1                     ;103.6607745044308 +13.1778471691893;  33.92; 4.715
96 ;* pi. CMa                          ;TYC 5958-3291-1                    ;103.9057895406099 -20.1364905432189;  33.56; 4.664
97 ;HD 49095                           ;TYC 7088-486-1                     ;101.3454372199561 -31.7936472651449;  41.65; 5.916
98 ;HD 53705                           ;TYC 7641-1530-1                    ;105.9887051640463 -43.6080220575159;  58.64; 5.28 
99 ;* lam Gem                          ;TYC 1346-1396-1                    ;109.5230298476092 +16.5403934797979;  32.33; 3.559
100;* rho Gem                          ;TYC 2452-2167-1                    ;112.27776642 +31.78455327          ;  55.41; 4.18 
101;NAME Wasat                         ;TYC 1359-2672-1                    ;110.0305249383 +21.9823209962      ;  53.94; 3.53 
102;HD 59380                           ;TYC 5396-1251-1                    ;112.3567230797939 -07.5512219584549;  33.63; 5.853
103;HD 59984                           ;TYC 5397-3754-1                    ;113.0238168863437 -08.8813103339864;  34.79; 5.916
104;HD 60532                           ;TYC 5991-217-1                     ;113.5130767279146 -22.2960452244289;  38.48; 4.39 
105;* n Pup B                          ;TYC 6539-3803-1                    ;113.5800668685730 -23.4748752204966;  32.81; 5.795
106;* n Pup A                          ;TYC 6539-3802-1                    ;113.5774016580695 -23.4736436664080;  32.74; 5.770
107;* 171 Pup                          ;TYC 7114-2950-1                    ;116.3953485403661 -34.1725483999466;  65.11; 5.362
108;* mu.02 Cnc                        ;TYC 1388-1791-1                    ;121.9408387827864 +21.5818252019897;  42.36; 5.30 
109;* zet01 Cnc A                      ;TYC 1381-1638-1                    ;123.0527823408233 +17.6476565325115;  41.30; 5.31 
110;* 18 Pup                           ;TYC 5438-2380-1                    ;122.6657463118683 -13.7991742479190;  44.16; 5.54 
111;NAME Tureis                        ;TYC 6555-3245-1                    ;121.8858607489 -24.3042884692      ;  51.33; 2.81 
112;* 1 Hya                            ;TYC 4857-2150-1                    ;126.1456841400650 -03.7512119110130;  37.30; 5.61 
113;HD 69830                           ;TYC 5435-2991-1                    ;124.5995812507400 -12.6321363423267;  79.59; 5.95 
114;* q Pup                            ;TYC 7134-2967-1                    ;124.6386551791 -36.6592404909      ;  34.93; 4.40 
115;HD 76151                           ;TYC 4873-1792-1                    ;133.5745651361370 -05.4344196143113;  59.35; 6.00 
116;HD 76932                           ;TYC 6014-255-1                     ;134.6828509155513 -16.1326752322090;  46.63; 5.86 
117;* kap Phe                          ;TYC 7530-978-1                     ;006.5505312981 -43.6799680088      ;  42.00; 3.94 
118;* lam02 For                        ;TYC 7014-630-1                     ;039.2439785412428 -34.5780841689666;  39.35; 5.79 
119;* tau PsA                          ;TYC 7492-1465-1                    ;332.5361914304792 -32.5485120792394;  54.49; 4.92 
120;* pi. PsA                          ;TYC 7509-1264-1                    ;345.8738707210630 -34.7495294525234;  35.37; 5.124
121;* tet Scl                          ;TYC 6995-1262-1                    ;002.9331008895272 -35.1332462166218;  46.19; 5.239
122;* kap For                          ;TYC 6433-2550-1                    ;035.6356043751994 -23.8169007553130;  44.09; 5.198
123;* eps Scl                          ;* eps Scl                          ;026.4112257811 -25.0527187900      ;  35.57; 5.31 
124;* eps Scl A                        ;TYC 6429-2283-1                    ;026.4112646364034 -25.0527222251268;  35.80; 5.340
125;* 37 Cap                           ;TYC 6369-1185-1                    ;323.7123281496445 -20.0843435585843;  36.16; 5.696
126;* tau01 Eri                        ;TYC 5866-1050-1                    ;041.2756099069903 -18.5727194962476;  70.74; 4.46 
127;* ups Aqr                          ;TYC 6392-1443-1                    ;338.6731200926336 -20.7083100599001;  43.58; 5.203
128;HD 4247                            ;TYC 5849-2437-1                    ;011.1846875175784 -22.0062410949532;  36.70; 5.220
129;* tau Cet                          ;TYC 5855-2292-1                    ;026.0167459446 -15.9375740083      ; 273.96; 3.50 
130;* mu. Cap                          ;TYC 5801-1017-1                    ;328.3236643772748 -13.5518495946425;  36.95; 5.08 
131;* 6 Cet                            ;TYC 5839-1081-1                    ;002.8157295579000 -15.4680819376594;  55.59; 4.89 
132;* eps Cet                          ;TYC 5288-1093-1                    ;039.8905760342645 -11.8721762305855;  42.49; 4.87 
133;* 94 Aqr                           ;TYC 5827-1271-1                    ;349.7776831306004 -13.4588738112740;  44.90; 5.18 
134;* chi Cet                          ;TYC 5278-2438-1                    ;027.3959939192 -10.6864929545      ;  43.13; 4.68 
135;HD 14691                           ;TYC 5282-2511-1                    ;035.5061274331139 -10.7776138296463;  33.33; 5.421
136;HD 16673                           ;TYC 5285-1109-1                    ;040.0515106834763 -09.4529461975502;  45.61; 5.790
137;* 37 Cet                           ;TYC 5273-2653-1                    ;018.5998853438142 -07.9229104195959;  41.83; 5.13 
138;HD 9562                            ;TYC 4687-2318-1                    ;023.4282023928059 -07.0254249656079;  33.63; 5.76 
140;* zet02 Aqr                        ;TYC 5226-1605-1                    ;337.2075161190126 -00.0200314340088;  34.51; 4.34 
141;* zet01 Aqr                        ;TYC 5226-1605-2                    ;337.2075061461126 -00.0206117548313;  34.45; 4.49 
142;* 16 Psc                           ;TYC 585-1200-1                     ;354.0966923674088 +02.1021506411719;  30.49; 5.68 
143;* lam Psc                          ;TYC 586-1458-1                     ;355.5113658444 +01.7799709824      ;  30.59; 4.51 
144;HD 4628                            ;TYC 17-1398-1                      ;012.0954340712251 +05.2805496677426; 134.50; 5.74 
145;NAME Biham                         ;TYC 565-1999-1                     ;332.5495819230 +06.1978070714      ;  35.34; 3.55 
146;* iot Psc                          ;TYC 591-1744-1                     ;354.9873456813 +05.6262270318      ;  72.92; 4.120
147;* del Equ                          ;TYC 1109-2583-1                    ;318.6196949695 +10.0069352525      ;  54.09; 4.49 
148;* ksi Peg                          ;TYC 1155-2186-1                    ;341.6729115044 +12.1728335932      ;  61.36; 4.20 
149;* 29 Ari                           ;TYC 1216-1511-1                    ;038.2253255155060 +15.0344902402468;  34.86; 5.988
150;* 13 Peg                           ;TYC 1670-919-1                     ;327.5358924680 +17.2858092858      ;  29.92; 5.336
151;NAME Helvetios                     ;TYC 1717-2193-1                    ;344.3662466471642 +20.7687887284501;  64.65; 5.46 
152;* 54 Psc                           ;TYC 1193-2072-1                    ;009.8405504050116 +21.2504381453298;  89.79; 5.88 
153;* rho Psc                          ;TYC 1201-1725-1                    ;021.5633047449043 +19.1723087372762;  39.07; 5.344
154;* 107 Psc                          ;TYC 1211-1733-1                    ;025.6237298943403 +20.2684800195593; 131.49; 5.24 
155;* 85 Peg                           ;TYC 1732-2731-1                    ;000.5427690554750 +27.0817689159362;  79.07; 5.75 
156;* iot Peg                          ;TYC 2208-2471-1                    ;331.7524075642915 +25.3450726331708;  84.76; 3.770
157;* mu. Cyg                          ;* mu. Cyg                          ;326.0353848883014 +28.7425998384566;  45.22; 4.50 
158;* 15 Peg                           ;TYC 2214-2037-1                    ;328.1242994228756 +28.7935148283090;  36.86; 5.53 
159;NAME Mothallah                     ;TYC 1763-3028-1                    ;028.2701618820 +29.5788056691      ;  51.50; 3.42 
160;* 13 Tri                           ;TYC 1778-1523-1                    ;037.2017866881143 +29.9317384464788;  31.70; 5.901
161;NAME Titawin                       ;TYC 2822-2210-1                    ;024.19904971 +41.40545029          ;  74.12; 4.10 
162;* tau01 Hya                        ;TYC 4885-1568-1                    ;142.2870281608573 -02.7689037681095;  56.29; 4.60 
163;* 11 LMi                           ;TYC 2500-1533-1                    ;143.9143475504810 +35.8101404619173;  89.26; 5.340
164;HD 84117                           ;TYC 6602-2081-1                    ;145.5598622051095 -23.9155008252387;  67.47; 4.94 
166;* 21 LMi                           ;TYC 2509-1343-1                    ;151.8570956330 +35.2446999345      ;  35.41; 4.49 
167;NAME Regulus                       ;TYC 833-1381-1                     ;152.09271726 +11.96724078          ;  41.13; 1.40 
168;HD 88215                           ;TYC 5485-1226-1                    ;152.5242975630192 -12.8158589211778;  35.95; 5.296
169;HD 91889                           ;TYC 5495-1546-1                    ;159.1346832081452 -12.2300541604863;  40.40; 5.70 
170;NAME Praecipua                     ;TYC 2521-2271-1                    ;163.3276759697 +34.2148789379      ;  34.38; 3.83 
171;* b03 Hya                          ;TYC 6082-2054-1                    ;163.3728059344100 -20.1386466382224;  33.07; 5.24 
172;NAME Zosma                         ;TYC 1439-2479-1                    ;168.52682282 +20.52374272          ;  55.82; 2.53 
173;* ksi UMa B                        ;TYC 2520-2634-2                    ;169.5448823993771 +31.5291275783309; 114.49; 4.77 
174;* iot Leo A                        ;TYC 858-1221-1                     ;170.9807867979080 +10.5295454439740;  42.35; 4.06 
175;* gam Crt                          ;TYC 6088-2155-1                    ;171.2202550208 -17.6839257448      ;  39.62; 4.06 
176;* 17 Crt B                         ;TYC 6663-1171-1                    ;173.0665754826755 -29.2632031778553;  35.98; 5.671
177;* 20 Crt                           ;TYC 7220-866-1                     ;173.6226030152385 -32.8312332025642; 104.78; 5.98 
178;* 61 UMa                           ;TYC 2525-2445-1                    ;175.2622923811875 +34.2016407158695; 104.39; 5.34 
179;* iot Crt                          ;TYC 5516-1578-1                    ;174.6664727367732 -13.2018610390397;  37.37; 5.48 
180;HD 102365                          ;TYC 7745-1381-1                    ;176.6292076353032 -40.5002379402005; 107.62; 4.88 
181;NAME Denebola                      ;TYC 870-988-1                      ;177.26463271 +14.57209176          ;  90.91; 2.13 
182;NAME Zavijava                      ;TYC 273-924-1                      ;177.6735488918 +01.7647735044      ;  91.50; 3.60 
183;HD 103026                          ;TYC 7218-629-1                     ;177.9230991842241 -30.8347016674704;  31.54; 5.85 
184;HD 104304                          ;TYC 5521-1366-1                    ;180.1849753029997 -10.4460524928712;  78.76; 5.55 
185;NAME Alchiba                       ;TYC 6672-995-1                     ;182.1031210979 -24.7287783622      ;  66.95; 4.00 
186;HD 104731                          ;TYC 7763-1047-1                    ;180.9146098892776 -42.4339347388238;  40.44; 5.15 
187;NAME Chara                         ;TYC 3020-2541-1                    ;188.4353157070 +41.3574753973      ; 118.49; 4.25 
188;* eta Crv                          ;TYC 6103-2394-1                    ;188.0173162133 -16.1959210643      ;  54.70; 4.294
189;NAME Algorab                       ;TYC 6103-2395-1                    ;187.4657698956 -16.5153471823      ;  37.55; 2.94 
190;* 10 CVn                           ;TYC 3021-2548-1                    ;191.2472293154746 +39.2789145204592;  56.93; 5.95 
191;* gam Vir A                        ;TYC 4949-1120-1                    ;190.4148016056840 -01.4493360358748;  86.06; 3.440
192;* gam Vir B                        ;TYC 4949-1120-2                    ;190.4144633286307 -01.4493431568018;  78.52; 3.49 
193;* alf01 CVn                        ;TYC 3021-2646-1                    ;194.0015031670335 +38.3148513846821;  30.39; 5.60 
194;NAME Vindemiatrix                  ;TYC 886-1326-1                     ;195.5438542516 +10.9591882284      ;  29.76; 2.79 
195;* bet Com                          ;TYC 1996-2400-1                    ;197.9680047481 +27.8781932118      ; 109.54; 4.25 
196;* 53 Vir                           ;TYC 6113-1726-1                    ;198.0144512835827 -16.1985230530030;  30.33; 5.04 
197;* 61 Vir                           ;TYC 6116-1517-1                    ;199.6009908570 -18.3111108411      ; 116.89; 4.740
198;HD 114613                          ;TYC 7774-1925-1                    ;198.0129392247411 -37.8029074628854;  49.27; 4.85 
199;* 70 Vir                           ;TYC 898-1042-1                     ;202.1072219353293 +13.7788217146810;  55.83; 4.970
200;* 66 Vir                           ;TYC 4965-1204-1                    ;201.1382181006091 -05.1638712864228;  32.18; 5.749
201;* iot Cen                          ;TYC 7275-2074-1                    ;200.1488899616554 -36.7121923960112;  56.06; 2.73 
202;NAME Heze                          ;TYC 4966-1366-1                    ;203.6729863778021 -00.5957818744014;  44.36; 3.38 
203;* tau Boo                          ;TYC 1460-132-1                     ;206.81528011 +17.45692903          ;  64.03; 4.49 
204;* i Cen                            ;TYC 7270-2399-1                    ;206.4215093049 -33.0436186208      ;  51.54; 4.23 
205;* pi. Hya                          ;TYC 6738-1381-1                    ;211.5925554195 -26.6822711531      ;  32.30; 3.28 
206;* 18 Boo                           ;TYC 915-1442-1                     ;214.8175017916501 +13.0043300423340;  38.13; 5.396
207;NAME Syrma                         ;TYC 4982-1645-1                    ;214.0032837731 -06.0004877124      ;  44.97; 4.08 
208;HD 125276                          ;TYC 6735-1289-1                    ;214.7533751221067 -25.8153389248303;  55.55; 5.87 
209;* sig Boo                          ;TYC 2021-1323-1                    ;218.6697435930860 +29.7451333559876;  63.86; 4.47 
210;* mu. Vir                          ;TYC 4997-942-1                     ;220.7647453213 -05.6581511260      ;  54.73; 3.88 
211;HD 130948                          ;TYC 2016-334-1                     ;222.5655433681042 +23.9118530552286;  54.91; 5.867
212;* ksi Boo A                        ;TYC 1481-722-1                     ;222.8471113072128 +19.1004725983888; 148.52; 4.675
213;* 16 Lib                           ;TYC 4995-951-1                     ;224.2954726008788 -04.3464114325543;  36.31; 4.49 
214;* alf01 Lib                        ;TYC 6155-1209-1                    ;222.6711991733818 -15.9971646746905;  42.79; 5.16 
215;HD 131977                          ;TYC 6180-855-1                     ;224.3662989091473 -21.4154077878378; 170.01; 5.72 
216;* eta Sco                          ;TYC 7882-569-1                     ;258.0378372837 -43.2391533982      ;  44.39; 3.33 
217;HD 147513                          ;TYC 7853-621-1                     ;246.0049315097414 -39.1929223779076;  77.47; 5.376
218;HD 191408                          ;TYC 7453-1391-1                    ;302.7992921316147 -36.1012668136407; 166.23; 5.32 
219;HD 155826                          ;TYC 7870-136-1                     ;258.8991304312957 -38.5939019040860;  30.40; 5.96 
220;* eps CrA                          ;TYC 7421-2296-1                    ;284.6802998119116 -37.1073483858174;  32.09; 4.83 
221;* gam CrA A                        ;TYC 7422-1737-1                    ;286.6045946573183 -37.0633010253587;  58.70; 4.53 
222;HD 165185                          ;TYC 7403-6261-1                    ;271.5983669558926 -36.0197798640238;  58.13; 5.95 
223;HD 199260                          ;TYC 6931-1018-1                    ;314.1967958097396 -26.2964524132439;  46.98; 5.70 
225;HD 192310                          ;TYC 6914-1943-1                    ;303.8220371224298 -27.0330318672279; 113.65; 5.723
226;* psi Cap                          ;TYC 6925-1576-1                    ;311.5234704034 -25.2709647836      ;  68.13; 4.122
227;NAME Alnasl                        ;TYC 7391-2710-1                    ;271.4515874282 -30.4240852333      ;  33.67; 2.99 
228;NAME Terebellum                    ;TYC 6899-2595-1                    ;298.9594135605082 -26.2994065572410;  42.72; 4.70 
229;* 36 Oph A                         ;TYC 6820-326-1                     ;258.8361702165099 -26.6016732333916; 167.82; 5.08 
230;* 36 Oph B                         ;TYC 6820-326-2                     ;258.8370071193598 -26.6027952523102; 167.78; 5.03 
231;NAME Kaus Borealis                 ;TYC 6861-3180-1                    ;276.9922341882 -25.4217057700      ;  41.72; 2.81 
232;* b Oph                            ;TYC 6825-462-1                     ;261.5921469027 -24.1752907527      ;  39.22; 4.153
233;HD 150453                          ;TYC 6225-405-1                     ;250.4733406208079 -19.9243308676106;  32.88; 5.559
234;HD 152311                          ;TYC 6226-1945-1                    ;253.3545779432191 -20.4157469596863;  35.43; 5.857
235;* ksi Oph                          ;TYC 6246-664-1                     ;260.2511444109 -21.1129145335      ;  57.62; 4.387
236;* 58 Oph                           ;TYC 6260-2072-1                    ;265.8570501544 -21.6831825495      ;  56.65; 4.861
237;HD 172051                          ;TYC 6279-1723-1                    ;279.7220798781738 -21.0518854781979;  76.64; 5.860
238;* 49 Lib                           ;TYC 6200-1429-1                    ;240.0813225441223 -16.5334389557223;  34.23; 5.47 
239;* ksi Ser                          ;TYC 6248-1369-1                    ;264.3962545879 -15.3985432978      ;  30.98; 3.519
240;HD 175317                          ;TYC 6281-2043-1                    ;283.8787720382811 -16.3766570373227;  31.91; 5.546
241;HD 184985                          ;TYC 5735-2725-1                    ;294.3929770319307 -14.3018389269390;  31.65; 5.451
242;* ksi Cap                          ;TYC 5748-2599-1                    ;303.1073909558535 -12.6175454594001;  35.40; 5.85 
243;NAME Giedi                         ;TYC 5749-2530-1                    ;304.5131643360 -12.5449015004      ;  30.82; 3.58 
244;* eps Lib                          ;TYC 5598-1052-1                    ;231.0491493068496 -10.3222122351053;  32.15; 4.920
245;* 37 Lib                           ;TYC 5599-955-1                     ;233.5442192716 -10.0644828022      ;  34.57; 4.62 
246;* ksi Sco A                        ;TYC 5619-1257-1                    ;241.0919294345210 -11.3731651975698;  35.31; 5.16 
247;* ksi Sco B                        ;TYC 5619-1257-2                    ;241.0918132630972 -11.3729224587688;  36.24; 4.87 
248;* 51 Aql                           ;TYC 5729-2871-1                    ;297.6945272404540 -10.7635489861975;  36.45; 5.39 
249;HD 189340                          ;TYC 5742-1060-1                    ;299.9465995365437 -09.9582734600882;  46.93; 5.88 
250;HD 195564                          ;TYC 5758-2542-1                    ;308.0983329839238 -09.8534371754051;  40.38; 5.65 
251;* 18 Sco                           ;TYC 5613-1378-1                    ;243.9049094419005 -08.3694061491904;  70.77; 5.50 
252;* 47 Oph                           ;TYC 5075-717-1                     ;261.6574424465 -05.0865885167      ;  33.25; 4.54 
253;* zet Ser                          ;TYC 5100-600-1                     ;270.1204835933 -03.6902715664      ;  42.46; 4.616
254;* eta Ser                          ;TYC 5102-416-1                     ;275.3271050364 -02.8988357657      ;  53.93; 3.25 
255;* 42 Aql                           ;TYC 5153-2875-1                    ;294.4467593284194 -04.6476735944121;  31.17; 5.68 
256;* 12 Oph                           ;TYC 5052-996-1                     ;249.0889899043710 -02.3245653035633; 100.81; 5.77 
257;HD 158614A                         ;TYC 5080-2188-1                    ;262.5987757450772 -01.0630163010986;  60.71; 5.981
258;* gam Oph                          ;TYC 420-1962-1                     ;266.9727770601 +02.7072764286      ;  31.73; 3.75 
259;* eps Ser                          ;TYC 360-1226-1                     ;237.7036619442 +04.4777561640      ;  46.30; 3.693
260;NAME Cebalrai                      ;TYC 423-1724-1                     ;265.8677499487 +04.5673032092      ;  39.85; 2.750
261;NAME Unukalhai                     ;TYC 363-1135-1                     ;236.0666157157 +06.4256530446      ;  44.10; 2.630
262;HD 162917                          ;TYC 429-1951-1                     ;268.3087204161300 +06.1014172305835;  32.47; 5.759
263;* mu. Aql                          ;TYC 490-5566-1                     ;293.5219208036 +07.3789112554      ;  30.31; 4.45 
264;NAME Alshain                       ;TYC 493-3355-1                     ;298.8279178738 +06.4067293944      ;  73.00; 3.710
265;* lam Ser                          ;TYC 364-1224-1                     ;236.6105330984608 +07.3530900373825;  84.61; 4.42 
266;* kap Oph                          ;TYC 979-2198-1                     ;254.4166995315 +09.3750379499      ;  35.66; 3.20 
267;* 72 Oph                           ;TYC 1012-1661-1                    ;271.8370495492 +09.5638388285      ;  37.55; 3.73 
268;* zet Aql                          ;TYC 1052-3027-1                    ;286.3521519660 +13.8634570982      ;  39.28; 2.99 
269;* 15 Del                           ;TYC 1098-1301-1                    ;312.4069918195127 +12.5450885203148;  32.92; 5.991
270;* gam Ser                          ;TYC 1496-2119-1                    ;239.1129039877 +15.6616293359      ;  88.86; 3.84 
271;* 111 Her                          ;TYC 1587-2067-1                    ;281.7547568690297 +18.1814938357053;  35.35; 4.36 
272;* 110 Her                          ;TYC 1591-1918-1                    ;281.4151469917 +20.5462940088      ;  52.06; 4.19 
273;* 15 Sge                           ;TYC 1621-1499-1                    ;301.0255449471464 +17.0701598346533;  56.43; 5.788
274;NAME Sarin                         ;TYC 2065-1930-1                    ;258.75759401 +24.83920112          ;  43.41; 3.13 
275;* mu.01 Her                        ;TYC 2085-3062-1                    ;266.6143255111 +27.7206670119      ; 120.33; 3.42 
276;* b Her A                          ;TYC 2621-2683-1                    ;271.7562550507268 +30.5620951499128;  63.54; 5.13 
277;* c Boo                            ;TYC 2024-1355-1                    ;226.8249352164831 +24.8692009573859;  51.67; 4.93 
278;* 53 Her                           ;TYC 2589-1303-1                    ;253.2415477945039 +31.7016631123799;  33.70; 5.333
279;* w Her                            ;TYC 2596-1317-1                    ;260.1645008282258 +32.4677339677548;  68.75; 5.39 
280;HD 176051A                         ;TYC 2643-3345-1                    ;284.2564416904771 +32.9012950181283;  66.57; 5.277
281;NAME Nusakan                       ;TYC 2032-1605-1                    ;231.9568720338 +29.1057019299      ;  29.17; 3.68 
282;* kap CrB                          ;TYC 2578-1609-1                    ;237.8077018901722 +35.6573727140906;  33.23; 4.82 
283;NAME Aljanah                       ;TYC 2695-4139-1                    ;311.5524788470 +33.9702363012      ;  44.86; 2.480
284;HD 147365                          ;TYC 3062-2252-1                    ;244.9794079327566 +39.7085720267966;  36.99; 5.480
285;* X Pup                            ;TYC 8117-1539-1                    ;102.4774769860069 -46.6145560667319;  39.70; 5.14 
286;HD 62644                           ;TYC 8134-800-1                     ;115.7377789959858 -45.1730813839084;  44.50; 5.04 
287;* I Pup                            ;TYC 8119-2547-1                    ;108.1400092175 -46.7592867486      ;  46.67; 4.49 
288;* eta01 Pic                        ;TYC 8081-1280-1                    ;075.7027616176056 -49.1514620427935;  38.22; 5.372
289;* bet Pic                          ;TYC 8099-1392-1                    ;086.8211246623 -51.0665443780      ;  51.44; 3.86 
290;* e Eri                            ;TYC 7567-1183-1                    ;049.9817013050 -43.0698856455      ; 165.47; 4.27 
291;* psi Vel A                        ;TYC 7696-2620-1                    ;142.6745221520342 -40.4667481977358;  54.61; 3.91 
292;* psi Vel B                        ;TYC 7696-2620-2                    ;142.6749889869383 -40.4666750219771;  54.31; 5.12 
293;* gam Dor                          ;TYC 8075-1796-1                    ;064.0065087565777 -51.4867279753608;  48.90; 4.20 
294;* zet Dor                          ;TYC 8514-2291-1                    ;076.3776908246622 -57.4727615405226;  86.02; 4.708
295;* q Vel                            ;TYC 7716-3704-1                    ;153.6837914446 -42.1218426195      ;  32.18; 3.85 
296;* M Vel                            ;TYC 8176-2817-1                    ;144.2062735553 -49.3549315165      ;  30.94; 4.35 
297;HD 76653                           ;TYC 8586-4328-1                    ;133.7990040138153 -54.9656895643586;  41.22; 5.698
298;HD 65907                           ;TYC 8911-793-1                     ;119.4454630289055 -60.3030214396985;  61.73; 5.60 
299;* 36 Dor                           ;TYC 8896-1535-1                    ;088.5252665850782 -63.0898068137876;  36.96; 4.66 
300;* eps Ret                          ;TYC 8508-2041-1                    ;064.1208971761 -59.3022429174      ;  54.83; 4.44 
301;* iot Hor                          ;TYC 8056-1164-1                    ;040.6392632713736 -50.8004158416219;  57.71; 5.40 
302;* b02 Car                          ;TYC 8594-3177-1                    ;134.8506946139024 -59.0836279034994;  38.18; 5.16 
303;* B Car                            ;TYC 8924-2780-1                    ;122.2523638875337 -61.3023286407107;  55.34; 4.742
304;* bet Ret                          ;TYC 8870-1385-1                    ;056.0499059960550 -64.8069884500645;  31.91; 3.833
305;* zet02 Ret                        ;TYC 8863-1557-1                    ;049.5533244084910 -62.5064825820946;  83.01; 5.228
306;* zet01 Ret                        ;TYC 8863-1556-1                    ;049.4422607977212 -62.5754375122783;  83.06; 5.54 
307;* kap Ret                          ;TYC 8870-1384-1                    ;052.3444187055 -62.9376393940      ;  46.12; 4.71 
308;* chi Eri                          ;TYC 8047-1321-1                    ;028.9891793715960 -51.6090172835566;  57.38; 3.70 
309;* q01 Eri                          ;TYC 8475-527-1                     ;025.6219158653543 -53.7409709701379;  57.66; 5.52 
310;* nu. Phe                          ;TYC 8033-1232-1                    ;018.7960768751786 -45.5318052142899;  65.89; 4.96 
311;* Y Vel                            ;TYC 8601-4516-1                    ;157.8407516809108 -53.7153658068769;  45.61; 4.89 
312;* alf Men                          ;TYC 9176-987-1                     ;092.5606041250358 -74.7530643254242;  97.90; 5.069
313;* alf Hyi                          ;TYC 8851-2027-1                    ;029.6922904476 -61.5700020902      ;  45.43; 2.84 
314;* p Eri B                          ;TYC 8478-1394-1                    ;024.9479601051603 -56.1965901474358; 122.13; 5.797
315;* p Eri A                          ;TYC 8478-1394-2                    ;024.9489943407037 -56.1934620337299; 122.06; 5.685
316;* gam Men                          ;TYC 9378-1692-1                    ;082.9704765377926 -76.3409584747102;  31.10; 5.20 
317;* u Car                            ;TYC 8627-3091-1                    ;163.3733895195 -58.8530432247      ;  34.33; 3.79 
318;* alf Cha                          ;TYC 9398-2714-1                    ;124.6318004493 -76.9196544170      ;  51.12; 4.047
319;* pi. Men                          ;TYC 9386-2614-1                    ;084.2918165660047 -80.4691611405093;  54.71; 5.67 
320;* iot Hyi                          ;TYC 9366-741-1                     ;048.9904507869731 -77.3885768202855;  34.12; 5.506
321;HD 4391                            ;TYC 8034-1159-1                    ;011.4396785329773 -47.5521256876706;  66.69; 5.794
322;* I Car                            ;TYC 9223-2880-1                    ;156.0987842007 -74.0314828608      ;  61.64; 4.01 
323;HD 3823                            ;TYC 8472-1356-1                    ;010.1066644009285 -59.4547527008873;  39.60; 5.88 
324;HD 3158                            ;TYC 8030-1170-1                    ;008.6156629834149 -52.3732369834042;  38.91; 5.57 
325;HD 99453                           ;TYC 8980-2291-1                    ;171.4295691236483 -63.9723349820112;  36.34; 5.17 
326;HD 10800                           ;TYC 9490-1295-1                    ;024.4815271437958 -82.9751451398998;  37.42; 5.87 
328;* zet Tuc                          ;TYC 8843-1706-1                    ;005.0174237327 -64.8749475660      ; 116.46; 4.23 
329;* eta Cru                          ;TYC 8982-4970-1                    ;181.7201576334 -64.6135830169      ;  50.62; 4.136
330;HD 221420                          ;TYC 9481-244-1                     ;353.3310853975058 -77.3854859121080;  32.08; 5.806
331;* del Mus                          ;TYC 9249-2380-1                    ;195.5674118095 -71.5487030843      ;  35.88; 3.62 
332;* nu. Oct                          ;TYC 9478-1483-1                    ;325.3681267237660 -77.3901250692543;  51.52; 3.728
333;* nu. Ind                          ;TYC 9340-2962-1                    ;336.1531106216192 -72.2555484373754;  35.14; 5.29 
334;HD 219482                          ;TYC 9126-1745-1                    ;349.2399545490429 -62.0013440140455;  48.88; 5.647
335;* gam Tuc                          ;TYC 8837-1297-1                    ;349.3568989049259 -58.2358941226075;  42.32; 3.98 
336;* omi Gru                          ;TYC 8832-440-1                     ;351.6519606978934 -52.7217157916321;  31.44; 5.522
337;HD 109409                          ;TYC 7770-2412-1                    ;188.6763428703551 -44.6728931518129;  35.10; 5.76 
338;HD 114837                          ;TYC 8661-2282-1                    ;198.5627598572167 -59.1030970516145;  55.01; 4.93 
339;HD 200525                          ;TYC 9333-1274-1                    ;317.3427877354478 -73.1730329850658;  49.35; 5.67 
340;* eps Pav                          ;TYC 9315-1893-1                    ;300.1473506846 -72.9105648922      ;  31.04; 3.940
341;* gam Pav                          ;* gam Pav                          ;321.6102820638 -65.3663054476      ; 107.97; 4.22 
342;HD 212330                          ;TYC 8828-1420-1                    ;336.2344312304244 -57.7975786028424;  48.89; 5.31 
343;* alf Cir                          ;TYC 9015-1445-1                    ;220.6262576950 -64.9750126417      ;  60.35; 3.19 
344;* zet TrA                          ;TYC 9278-2951-1                    ;247.1165592483267 -70.0843173216172;  82.12; 4.899
345;* eps Ind                          ;TYC 8817-984-1                     ;330.8397548858438 -56.7861006343115; 274.80; 4.69 
346;HD 211415                          ;TYC 8822-1455-1                    ;334.5646150634104 -53.6271963231185;  71.34; 5.39 
347;* del Pav                          ;TYC 9098-1918-1                    ;302.1810541879 -66.1821313091      ; 163.71; 3.56 
348;* bet Cir                          ;TYC 8706-1061-1                    ;229.3780546011 -58.8010987137      ;  32.73; 4.057
349;* bet TrA                          ;TYC 9027-5712-1                    ;238.7851143034 -63.4306343433      ;  80.79; 2.85 
350;HD 172555                          ;TYC 9077-2487-1                    ;281.3614239372 -64.8712719239      ;  35.03; 4.767
351;* phi01 Pav                        ;TYC 9100-1712-1                    ;308.8946382168900 -60.5818316424213;  35.34; 4.745
352;* phi02 Pav                        ;TYC 9100-1218-1                    ;310.0104278097375 -60.5489747878580;  40.15; 5.121
353;HD 139211                          ;TYC 8708-1079-1                    ;234.9850832030684 -59.9082387399326;  32.69; 5.95 
354;* iot Pav                          ;TYC 9059-2661-1                    ;272.6083412187988 -62.0022274032386;  56.33; 5.49 
355;HD 207129                          ;TYC 8437-31-1                      ;327.0651881116949 -47.3037238496229;  64.26; 5.58 
356;* eta Ind                          ;TYC 8419-1619-1                    ;311.0092240917 -51.9210520432      ;  41.37; 4.495
357;* nu.02 Lup                        ;TYC 8298-1229-1                    ;230.4501887713991 -48.3175334184409;  68.08; 5.65 
358;* eps02 Ara                        ;TYC 8726-2209-1                    ;255.7859379998560 -53.2368894384306;  38.22; 5.278
359;* alf Ind                          ;TYC 8411-1822-1                    ;309.3913182468 -47.2915763080      ;  33.17; 3.11 
360;NAME Cervantes                     ;TYC 8355-436-1                     ;266.0357353753477 -51.8340285627751;  64.08; 5.15 
361;HD 201647                          ;TYC 7970-450-1                     ;318.0566973572801 -40.2694493764364;  31.42; 5.83 
362;* g Lup                            ;TYC 7849-3219-1                    ;235.2969624965 -44.6611227841      ;  57.35; 4.633
363;* lam Ara                          ;TYC 8351-2577-1                    ;265.0987582089027 -49.4155587557134;  47.10; 4.77 
364;* ksi Cep A                        ;TYC 4271-2620-1                    ;330.9471989085320 +64.6279601005881;  32.17; 4.45 
365;HD 41742A                          ;TYC 8101-1755-1                    ;091.1669828157124 -45.0789489404085;  36.59; 5.966
366;* kap Tuc A                        ;TYC 9136-1759-2                    ;018.9418660132393 -68.8760599888327;  47.67; 4.95 
367;* gam Cet A                        ;TYC 50-1721-1                      ;040.8249154224304 +03.2357679177666;  43.60; 3.540
368;* sig CrB A                        ;TYC 2583-1846-1                    ;243.6698715786337 +33.8586063755224;  44.13; 5.55 
369;* 41 Ara A                         ;TYC 8341-4366-1                    ;259.7654943559972 -46.6361972778364; 113.82; 5.52 
370;NAME Dalim                         ;TYC 6445-990-1                     ;048.0186610557512 -28.9877109932566;  71.68; 3.98 
371;* rho Cap A                        ;TYC 6333-2128-1                    ;307.2146498026229 -17.8137424750766;  33.31; 4.93 
372;* m Hya A                          ;TYC 6750-1743-1                    ;221.4999737824299 -25.4431015478977;  32.76; 5.11 
373;* 212 Pup A                        ;TYC 7127-2092-2                    ;118.0651140020273 -34.7054094174391;  54.78; 5.12 
374;* tet Ind A                        ;TYC 8793-1478-1                    ;319.9661413511618 -53.4495223112086;  33.93; 4.483
375;* zet02 Cnc                        ;TYC 1381-1641-1                    ;123.0548419607499 +17.6484542567350;  42.13; 5.79 
376;* 84 Cet A                         ;TYC 4699-1214-1                    ;040.3080757385516 -00.6957146311331;  45.04; 5.77 
377;HD 142                             ;TYC 8025-341-1                     ;001.57956805 -49.07533067          ;  38.89; 5.76 
378;* iot UMa A                        ;TYC 3420-2149-1                    ;134.8016795799385 +48.0418302232765;  70.64; 3.13 
379;HD 49933A                          ;TYC 4800-2672-1                    ;102.7074248532981 -00.5408779895175;  33.44; 5.771
380;* 39 Leo                           ;TYC 1969-1262-1                    ;154.3103248877698 +23.1062382044756;  44.78; 5.82 
381;HD 199623                          ;TYC 8433-492-1                     ;315.0890234097504 -51.2654021939675;  36.63; 5.761
382;HD 189245                          ;TYC 7444-1240-1                    ;300.0839237478342 -33.7035040291420;  45.39; 5.66 
383;* 17 Crt A                         ;TYC 6663-1169-1                    ;173.0680937461754 -29.2609199887273;  36.06; 5.58 
384;* phi02 Cet                        ;TYC 5270-1139-1                    ;012.5313221222332 -10.6444195784214;  62.97; 5.19 
================================================================================
`