import React, { useState } from 'react'

function NotFound() {

    return (<>
        Page Not Found
    </>)
}

export default NotFound
